import React from "react"
import styled from 'styled-components'
import { Theme } from "@Styles/Theme"
import { useStaticQuery, graphql } from "gatsby"

const LoaderPageWrapper = styled.div`
  position: fixed;
  top:0;
  left:0;
  bottom: 0;
  right:0;
  z-index: 100000;
  background-color: #ffffff;

  p{
    text-transform: uppercase;
    font-style: italic;
    font-size: 30px;
    color: ${Theme.colors.red};
    font-weight: 900;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 10vh;
    transform: translateX(-50%);
  }
`

const LoaderGrey = styled.div`
  width: 400px;
  height: 210px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: ${props => `url(${props.loaderGrey})`};
  background-size: contain;
  background-repeat: no-repeat;

  img{
    width: 100%;
    height: auto;
    filter: grayscale(100%);
    opacity: .7;
  }
`

const LoaderBlue = styled.div`
  width: 400px;
  height: 208px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mask-image: ${props => `url(${props.loaderGrey})`};
  mask-repeat: no-repeat;
  mask-position: 50% 0%;
  mask-size: 99.5%;
  mix-blend-mode: hard-light;


  img{
    width: 100%;
    height: auto;
  }

  .waves {
    position:absolute;
    bottom:0;
    width: 100%;
    height: 3rem;
    margin-bottom:0px; /*Fix for safari gap*/
    min-height:30px;
    max-height:30px;
  }
  
  .content {
    position:relative;
    height:2vh;
    text-align:center;
    background-color: white;
  }
  
  /* Animation */
  
  .parallax > use {
    animation: move-forever 4s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: 0s;
    animation-duration: 2s;
    opacity: .5;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -1s;
    animation-duration: 1s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }

  .blueFilter{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: hsl(186, 60%, 25%);
    opacity: 1;
  }

`


export default function Loader() {


  let dataImages = useStaticQuery(graphql`
  query LoaderEyes {
    eyes: file(relativePath: {eq: "logos/loading_yeux.png"}) {
      childImageSharp {
        fluid(maxWidth: 800){
          aspectRatio
          base64
          originalName
          sizes
          src
        }
      }
    }
  }
`)



  return (
    <LoaderPageWrapper id="loaderPageWrapper">
      <LoaderGrey loaderGrey={dataImages.eyes.childImageSharp.fluid.src}>
      </LoaderGrey>
      <LoaderBlue id="loaderBlue" loaderGrey={dataImages.eyes.childImageSharp.fluid.src}>
        <svg className="waves"
          viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="hsl(186, 60%, 25%)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="hsl(186, 60%, 25%)" />
          </g>
        </svg>
        <div className="blueFilter" id="blueFilter"></div>
      </LoaderBlue>
      <p>CHARGEMENT</p>
    </LoaderPageWrapper >
  )
}