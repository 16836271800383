import React from "react"
import Loader from "./Loader"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default function Wrapper({ children }) {

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {

    const waves = document.getElementsByClassName('waves')[0]
    const blueFilter = document.getElementById('blueFilter')
    const loaderPageWrapper = document.getElementById('loaderPageWrapper')

    if (isLoading) {
      setTimeout(() => {
        var tl = gsap.timeline({});
        tl.to(waves, {
          bottom: "100%",
          duration: 3,
        }, 0)

        tl.to(blueFilter, {
          height: "100%",
          duration: 3
        }, 0)

        tl.to(loaderPageWrapper, {
          opacity: 0,
          duration: .5
        })
        tl.to(loaderPageWrapper, {
          zIndex: -10,
          duration: .5,
          onComplete: () => setIsLoading(false)
        })
      }, 1250)
    }
  }, [])


  return (
    <>
      <Loader />
      {children}
    </>
  )
} 